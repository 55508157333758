// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.common-btn{
    background-color: #9ac96c;
    color: #fff;
    border: none;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 4px;
    display: block;
    margin: 20px auto;
    padding: .7rem 3.5rem;
    transition: all 0.08s ease-in-out 0s;
}


.common-btn:hover {
    background-color: #79ba39;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 8px;
}

.icon-arrow{
    margin-left: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,0CAA0C;IAC1C,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,oCAAoC;AACxC;;;AAGA;IACI,yBAAyB;IACzB,0CAA0C;AAC9C;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n.common-btn{\n    background-color: #9ac96c;\n    color: #fff;\n    border: none;\n    border-radius: .5rem;\n    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 4px;\n    display: block;\n    margin: 20px auto;\n    padding: .7rem 3.5rem;\n    transition: all 0.08s ease-in-out 0s;\n}\n\n\n.common-btn:hover {\n    background-color: #79ba39;\n    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 8px;\n}\n\n.icon-arrow{\n    margin-left: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
