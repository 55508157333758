import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../css/welcome.css';
import ProgressBar from './Progressbar';
import '../css/heder.css';
import back from '../images/back.png';
import { useSelector } from 'react-redux';
import { USER_DATA } from '../utils/constants/user';

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.user[USER_DATA]);

  const [showBackButton, setShowBackButton] = useState(false);
  useEffect(() => {

    setShowBackButton(location.pathname !== '/' && location.pathname !== '/appointmentconfirmation' && window.history.length > 1);
  }, [location]);


  function calculateAge(dateString) {
    const birthDate = new Date(dateString);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }

    return age;
  }

  function redirectToPageBasedOnAge(dateString) {
    const age = calculateAge(dateString);

    if (age >= 18) {
      // Redirect to a page for users 18 and older
      navigate('/userdetails');
    } else {
      // Redirect to a page for users under 18
      navigate('/userdetails2');
    }
  }

  // Example usage:

  const goBack = () => {
    if (window.location.pathname?.includes('appointmentbooking')) {
      redirectToPageBasedOnAge(userData?.date_of_birth)
    } else if (props.backRoute) {
      navigate(props.backRoute);
    } else {
      navigate(-1);
    }

  };

  return (
    <header className="fixed-top">
      <div className="row" style={{ alignItems: "center !important" }}>
        <div className="py-2 col-lg-7 col-md-8 col-sm-8 col-11 mx-auto">
          <div className="row">
            <div className="col mt-4">
              <div className="back-logo-container">
                {showBackButton && (
                  <p type="button" className="back-button rounded-icon" onClick={goBack}>
                    <img src={back} alt="Back" className="rounded-icon-image" />
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="nav-brand">
            <img
              src="https://geteverlong.com/wp-content/uploads/2023/07/Logo-1024x212.png"
              alt="Logo"
              className="nav-logo"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="mt-2 col-lg-8 col-md-8 col-sm-8 col-10 mx-auto">
          <ProgressBar value={props.progressValue} />
        </div>
      </div>
    </header>
  );
};

export default Header;
