// components/CountdownTimer.js

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setTimer,
  clearAllState,
} from "../redux/Slice/countdownSlice";
import { clearWelcomeState } from "../redux/Slice/welcomeSlice";
import { clearUserdetailsState } from "../redux/Slice/UserdetailsSlice";
import { clearAppointmentBookingState } from "../redux/Slice/appoinmentBookingSlice";
import { clearAppointmentconfirmationState } from "../redux/Slice/appointmentconfirmationSlice";
import { clearCreditCardInfoState } from "../redux/Slice/CreditCardInfoSlice";
import { cleardateOfBirthState } from "../redux/Slice/dateOfBirthSlice ";
import { clearinsuranceinformationState } from "../redux/Slice/insuranceinformationSlice";
import { clearinsuranceState } from "../redux/Slice/insuranceSlice";
import { clearSelectStatesState } from "../redux/Slice/SelectStatesSlice";
import { clearUserdetails2State } from "../redux/Slice/UserdetailsSlice2";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from "react-router-dom";

const CountdownTimer = ({ callFrom }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [lastTime, setLastTime] = useState(0); // Initialize with 0
  const [initialTimestamp, setInitialTimestamp] = useState(Date.now());

  useEffect(() => {
    // Load timer data from local storage when the component mounts
    if (window.location.pathname.includes("appointmentbooking")) {
      clearAllThings();
    }
    const localstorageTime = localStorage.getItem("timer");
    if (localstorageTime) {
      setLastTime(Number(localstorageTime));
    }
    const localstorageTimestamp = localStorage.getItem("timestamp");
    if (localstorageTimestamp) {
      const elapsedSeconds = Math.floor(
        (Date.now() - Number(localstorageTimestamp)) / 1000
      );
      setLastTime(Math.max(0, lastTime - elapsedSeconds)); // Adjust initial time remaining
      setInitialTimestamp(Number(localstorageTimestamp));
    } else {
      localStorage.setItem("timestamp", initialTimestamp);
    }
  }, []); // Only run this effect on initial mount

  const clearAllThings = () => {
    const currentTime = Date.now();
    dispatch(clearAllState()); // this is for counter
    dispatch(clearWelcomeState());
    dispatch(clearUserdetailsState());
    dispatch(clearAppointmentBookingState());
    dispatch(clearAppointmentconfirmationState());
    dispatch(clearCreditCardInfoState());
    dispatch(cleardateOfBirthState());
    dispatch(clearinsuranceinformationState());
    dispatch(clearinsuranceState());
    dispatch(clearSelectStatesState());
    dispatch(clearUserdetails2State());
    localStorage.removeItem("timer");
    localStorage.setItem("timestamp", currentTime);
  };

  useEffect(() => {
    const initialTime = 15 * 60; // 15 minutes in seconds

    const interval = setInterval(() => {
      dispatch(setTimer());
      const currentTime = Date.now();
      const elapsedTime = Math.floor((currentTime - initialTimestamp) / 1000);
      let last = lastTime;
      if (elapsedTime >= initialTime) {
        // Countdown completed
        clearAllThings();
        clearInterval(interval);
        setLastTime(initialTime);
        setInitialTimestamp(currentTime);
        navigate("/appointmentbooking");
      } else {
        last = initialTime - elapsedTime;
        setLastTime(last);
        localStorage.setItem("timer", last);
      }
    }, 0);

    return () => {
      clearInterval(interval);
    };
  }, [initialTimestamp, lastTime, dispatch, navigate]);

  const minutes = Math.floor(lastTime / 60);
  const seconds = lastTime % 60;

  // Custom styling
  const timerStyle = {
    fontSize: "28px",
    lineHeight: "33.6px",
    color: "#79ba39",
    textAlign: "center",
  };

  const messageStyle = {
    fontSize: "19.2px",
    color: "#79ba39",
    textAlign: "center",
  };

  console.log(minutes, callFrom);

  return (
    <>
      {!(minutes === 0 && seconds === 0) && (
        <h6 className={`${callFrom === "dietitianScreen" ? 'custom-timer' : 'custom-timer'} ${minutes > 2 ? 'regular-timer-text' : 'reduced-timer-text'}`}>
          <AccessTimeIcon style={{ fontWeight: "lighter", fontSize: "20px", marginRight: "5px", marginBottom: "1px" }} />
          {(callFrom === "dietitianScreen") ? "Holding your appointment:" : ""} {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h6>
      )}
    </>
  );
};

export default CountdownTimer;