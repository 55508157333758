import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import Header from "./header";
import "../css/AppointmentBooking.css";
import Calendar from "react-calendar";
import moment from "moment";
import Spinner from "./Spinner";
import Profile from "../images/pro.png";
import CountdownTimer from "./CountdownTimer";
import { useSelector, useDispatch } from "react-redux";
import "../css/DietitianScreen.css";
import { useCookies } from "react-cookie";
import { getUserData, updateUserData } from "../redux/action/userAction";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import {
  clearDietitianData,
  getDietitian,
  getOrganizationMembers,
  getSlotes,
  updateOrganizationData,
} from "../redux/action/insurenceProviderAction";
import {
  ORGANIZATION_MEMBERS_LIST,
  SLOT_LIST,
} from "../utils/constants/insurence-provider";
import { updateTimeRemaining } from "../redux/Slice/countdownSlice";
import DititianBlock from "./DititianBlock/DititianBlock";
import TransitionAnimation from "./Animation/TransitionAnimation";
import { HEALTH_CONCERN_LIST } from "../utils/constants/health-concern";
import { getHealthConcernList } from "../redux/action/healthConcernsAction";
import { Modal } from "react-bootstrap";
import { dateFormate } from "../utils/helpers/CommonHelper";
import CommonButton from "./Button/Button";
import { Close } from "@mui/icons-material";

const AppointmentBooking = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const dispatch = useDispatch();
  const progressValue = 60;
  const navigate = useNavigate();
  const [userState, setState] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageCenter, setErrorMessageCenter] = useState(null);
  const [selectedInsurance, setInsurance] = useState({});
  const [selectedSlots, setSelectedSlots] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [dietitianData, setDietitianData] = useState({});
  const [loading, setLoading] = useState(true);
  const [slotloading, setSlotLoading] = useState(false);
  const [calenderloading, setCalenderLoading] = useState(false);
  const [subOptionsList, setSubOptionsList] = useState([]);
  const [hidePrevArrowOnMonth, setHidePrevArrowOnMonth] = useState();
  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const organizationMembers = useSelector(
    (state) => state.insurence[ORGANIZATION_MEMBERS_LIST]
  );
  const healthConcersList = useSelector(
    (state) => state.healthConcerns[HEALTH_CONCERN_LIST] || []
  );
  const [modalLoading, setModalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
    // setModalLoading(true);
    // setTimeout(() => { 
    //   setModalLoading(false);
    //  }, 1500);
  };

  const [slotTime, setSlotTime] = useState("");
  const [dietitianModal, setDietitianModal] = useState({});

  useEffect(() => {
    console.log(userData.specialty, healthConcersList)
    if (healthConcersList.length > 0) {
      let newData = healthConcersList.find((data) => data.id === userData?.specialty?.id);
      console.log(newData?.subOptions)
      setSubOptionsList(newData?.subOptions)
    }
  }, [healthConcersList])

  const slotList = useSelector((state) => state.insurence[SLOT_LIST]);

  const [title, setTitle] = useState("");

  const getHealthList = () => {
    dispatch(getHealthConcernList());
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/appointmentbooking",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  const setUserDataHandler = (uData) => {
    if (uData.insurence && uData.insurence.insurance_provider_id) {
      setInsurance({
        value: uData.insurence.insurance_provider_id,
        label: uData.insurence.insurance_provider_name,
      });
    } else {
      setInsurance({});
    }

    if (uData.state) {
      setState(uData.state);
      let date = new Date();
      if (uData.appointment_data && uData.appointment_data.date) {
        // let clDate = uData.appointment_data && uData.appointment_data.date ? moment(uData.appointment_data.date).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        if (uData.state && uData.state.value) {
          getAvailableSlotes({
            date: moment(date).format("YYYY-MM-DD"),
            member_ids: organizationMembers.members
              ? organizationMembers.members.map((mem) => mem.id)
              : [],
            evuid: cookies["evuid"],
            appt_type_id:
              organizationMembers?.members?.[0]?.appointment_types?.[0]?.id,
            initial: true,
          });
        }
      } else {
        if (uData.state.value) {
          getAvailableSlotes({
            date: moment(date).format("YYYY-MM-DD"),
            member_ids: organizationMembers.members
              ? organizationMembers.members.map((mem) => mem.id)
              : [],
            evuid: cookies["evuid"],
            appt_type_id:
              organizationMembers?.members?.[0]?.appointment_types?.[0]?.id,
            initial: true,
          });
        }
      }
    }
  };

  const getSloteApiCall = (formattedDate, resDataMem) => {
    dispatch(
      getSlotes(
        {
          date: formattedDate,
          member_ids: resDataMem.data.result?.members.map((mem) => mem.id),
          appt_type_id:
            resDataMem.data.result?.members?.[0]?.appointment_types?.[0]?.id,
          time_zone: getTimezone(),
          available_dates: resDataMem.data.result.dates,
        },
        (resData) => {
          setSlotLoading(false);
          setErrorMessageCenter(null);

          if (resData && !resData.data.success) {
            if (resData?.data?.result === true) {
              setErrorMessageCenter(resData.data.message);
            } else {
              if (resData.data && resData.data.result.available_dates) {
                const latestData = {
                  members: resDataMem.data.result.members,
                  dates: resData.data.result.available_dates,
                };
                dispatch(updateOrganizationData(latestData));
                setErrorMessageCenter(resData.data.message);
              } else {
                setErrorMessage(resData.data.message);
              }
            }
          }
          const windowHeight = window.innerHeight;
          const scrollAmount = windowHeight * 0.1;
          window.scrollTo({
            top: scrollAmount,
            behavior: "smooth",
          });
        }
      )
    );
  };

  const getAvailableSlotes = (bodyData) => {
    clearSelectedSlot();
    setCalenderLoading(true);
    dispatch(
      getOrganizationMembers(
        {
          state_code: userState.value || bodyData.state_code,
          evuid: cookies["evuid"],
          cal_date: moment(bodyData.date).format("YYYY-MM-DD"),
          time_zone: getTimezone(),
        },
        (resDataMem) => {
          setCalenderLoading(false);
          if (resDataMem && !resDataMem.data.success) {
            if (resDataMem?.data?.result === true) {
              if (bodyData.initial) {
                setSelectedDate("");
                getAvailableSlotesNextMonth(bodyData);
              } else {
                bodyData.date && setSelectedDate(bodyData.date);

                setErrorMessageCenter(resDataMem.data.message);
              }
            } else {
              setErrorMessage(resDataMem.data.message);
            }
          } else if (
            resDataMem &&
            resDataMem.data.success &&
            resDataMem.data.result
          ) {
            if (
              resDataMem.data.result.dates &&
              resDataMem.data.result.dates.length > 0
            ) {
              if (
                resDataMem.data.result.members &&
                Array.isArray(resDataMem.data.result.members)
              ) {
                let date = moment(resDataMem.data.result.dates[0]);
                let formattedDate = date.format("YYYY-MM-DD");
                setSelectedDate(date);
                setSlotLoading(true);
                getSloteApiCall(formattedDate, resDataMem);
              }
            } else {
              setSelectedDate("");
              if (bodyData.initial) {
                getAvailableSlotesNextMonth(bodyData);
              }
            }
          }
        }
      )
    );
  };

  const getAvailableSlotesNextMonth = (bodyData) => {
    if (!isInCurrentMonth(bodyData.date)) {
      return;
    }
    setHidePrevArrowOnMonth(moment().format("YYYY-MM-DD"));

    const nextMonthFirstDate = getFirstDateOfNextMonth();
    clearSelectedSlot();
    setCalenderLoading(true);
    const formattedDate = moment(nextMonthFirstDate);
    dispatch(
      getOrganizationMembers(
        {
          state_code: userState.value || bodyData.state_code,
          evuid: cookies["evuid"],
          cal_date: formattedDate.format("YYYY-MM-DD"),
          time_zone: getTimezone(),
        },
        (resDataMem) => {
          setCalenderLoading(false);
          if (resDataMem && !resDataMem.data.success) {
            if (resDataMem?.data?.result === true) {
              formattedDate && setSelectedDate(formattedDate);
              setErrorMessageCenter(resDataMem.data.message);
            } else {
              setErrorMessage(resDataMem.data.message);
            }
          } else if (
            resDataMem &&
            resDataMem.data.success &&
            resDataMem.data.result
          ) {
            if (
              resDataMem.data.result.dates &&
              resDataMem.data.result.dates.length > 0
            ) {
              if (
                resDataMem.data.result.members &&
                Array.isArray(resDataMem.data.result.members)
              ) {
                let date = moment(resDataMem.data.result.dates[0]);
                let formattedDate = date.format("YYYY-MM-DD");
                setSelectedDate(date);
                setSlotLoading(true);
                getSloteApiCall(formattedDate, resDataMem);
              }
            } else {
              setSelectedDate("");
            }
          }
        }
      )
    );
  };

  function getFirstDateOfNextMonth() {
    const today = new Date(); // Get the current date as a Date object
    const nextMonth = new Date(today); // Create a copy of the current date

    // Increment the month value to get the next month
    nextMonth.setMonth(today.getMonth() + 1);

    // Set the day of the next month to 1 to get the first day
    nextMonth.setDate(1);

    return nextMonth;
  }

  function isInCurrentMonth(inputDate) {
    const today = new Date(); // Get the current date as a Date object

    // Convert the input date string to a Date object
    const parsedInputDate = new Date(inputDate);

    // Compare the year and month of the input date with the current date
    return (
      parsedInputDate.getFullYear() === today.getFullYear() &&
      parsedInputDate.getMonth() === today.getMonth()
    );
  }

  function getTimezone() {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimezone;
  }

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
      getHealthList();
    }

    if (
      !healthConcersList ||
      (Array.isArray(healthConcersList) && healthConcersList.length === 0)
    ) {
      getHealthList();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (
      selectedInsurance &&
      selectedInsurance.label === "Aetna Choice POS II / Open Access / PPO"
    ) {
      setTitle("Book your 55-minute initial appointment");
    } else if (selectedInsurance && selectedInsurance.label === "Aetna HMO") {
      setTitle("Book your 55-minute initial appointment");
    } else if (selectedInsurance && selectedInsurance.label === "Private Pay") {
      setTitle("Book your 55-minute initial appointment");
    } else if (selectedInsurance && selectedInsurance.label === "HSA / FSA") {
      setTitle("Book your 55-minute initial appointment");
    } else {
      setTitle("Book your 70-minute initial appointment");
    }
  }, [selectedInsurance]);

  const toggleSlotSelection = (slot, slotList) => {
    console.log(slotList?.slotLength)
    if (slot.date) {
      // setLoading(true);
      setSelectedSlots(slot.date);
      setDietitianData({
        ...dietitianData,
        user_id: slot.user_id ? slot.user_id : "",
      });

      // console.log(slot.date, slotList?.slotLength, slotList?.dietitian)
      const slotDate = moment(slot?.date, "YYYY-MM-DD HH:mm:ss");
      const updatedDate = slotDate.clone().add(slotList?.slotLength, 'minutes');
      const formattedStartTime = slotDate.format("h:mm A");
      const formattedEndTime = updatedDate.format("h:mm A");
      // console.log(formattedStartTime , formattedEndTime);      
      setSlotTime(formattedStartTime + " - " + formattedEndTime);
      setDietitianModal(slotList?.dietitian);

      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          appointment_data: {
            slot: slot.date ? slot.date : "",
            slotLength: slotList?.slotLength,
          },
          dietitian_data: {
            user_id: slot.user_id,
            designation: slotList?.dietitian?.designation,
          },
        })
      );
      // setTimeout(() => {
      //   setLoading(false);
      handleSelectButton(slot.date);
      // }, 1500);
    } else {
      setSelectedSlots("");
      setDietitianData({});
    }
  };

  const handleSelectButton = (selectedSlots) => {
    dispatch(clearDietitianData());
    if (errorMessageCenter) return;
    if (selectedSlots.length === 0) {
      setErrorMessage("Please select an appointment time.");
    } else {
      // dispatch(
      //   getDietitian({
      //     evuid: cookies["evuid"],
      //   })
      // );
      // setErrorMessageCenter(null);
      // setErrorMessage(null);
      //temp fix
      handleShow();
      // navigate("/dietitian");
    }
  };

  useEffect(() => {
    dispatch(updateTimeRemaining(15 * 60));
  }, []);

  const tileDisabled = ({ date, view }) => {
    if (view === "year") {
      // If in year view, enable all years in the future
      const today = new Date();
      const currentYear = today.getFullYear();
      const year = date.getFullYear();
      if (year === currentYear) {
        return date.getMonth() < today.getMonth();
      }
      return year < currentYear;
    }

    // If not in month or year view, use your existing isValidDate logic
    return !isValidDate(date);
  };

  const isValidDate = (date) => {
    const today = new Date();

    if (
      organizationMembers &&
      Array.isArray(organizationMembers.dates) &&
      organizationMembers.dates.includes(moment(date).format("YYYY-MM-DD"))
    ) {
      return true;
    }

    return false;
  };

  const tileClassName = ({ date }) => {
    if (isValidDate(date)) {
      if (
        selectedDate &&
        selectedDate instanceof Date &&
        selectedDate.toDateString() === date.toDateString()
      ) {
        return "selected-date";
      }
      return "valid-date";
    }
    return "gray-background";
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        appointment_data: {
          date: date,
        },
      })
    );
    clearSelectedSlot();
    if (
      organizationMembers &&
      organizationMembers.members &&
      organizationMembers.members.length > 0
    ) {
      if (Array.isArray(organizationMembers.members)) {
        setSlotLoading(true);
        dispatch(
          getSlotes(
            {
              date: moment(date).format("YYYY-MM-DD"),
              member_ids: organizationMembers.members.map((mem) => mem.id),
              appt_type_id:
                organizationMembers.members[0]?.appointment_types?.[0]?.id,
              time_zone: getTimezone(),
              available_dates: organizationMembers.dates,
            },
            (resData) => {
              setSlotLoading(false);
              setErrorMessageCenter(null);
              if (resData && !resData.data.success) {
                if (resData?.data?.result === true) {
                  setErrorMessageCenter(resData.data.message);
                } else {
                  if (resData.data && resData.data.result.available_dates) {
                    const latestData = {
                      members: organizationMembers.members,
                      dates: resData.data.result.available_dates,
                    };
                    dispatch(updateOrganizationData(latestData));
                    setErrorMessageCenter(resData.data.message);
                  } else {
                    if (resData.data && resData.data.result.available_dates) {
                      const latestData = {
                        members: organizationMembers.members,
                        dates: resData.data.result.available_dates,
                      };
                      dispatch(updateOrganizationData(latestData));
                      setErrorMessageCenter(resData.data.message);
                    } else {
                      setErrorMessage(resData.data.message);
                    }
                  }
                }
              }
            }
          )
        );
      }
    }
  };

  const generateSlotButton = (slotArray, timeStr, slotList) => {
    return slotArray.map((slot, index) => {
      const slotTime = slot.date
        ? moment(slot.date, "YYYY-MM-DD HH:mm:ss Z").format("h:mm A")
        : "--";
      // console.log(dietitianData.user_id, slot.user_id, dietitianData.user_id === slot.user_id)
      return (
        <div className="mb-2" key={slotTime + index + timeStr}>
          {slotTime === "--" ? (
            "--"
          ) : (
            <button
              className={`btn time-btn ${selectedSlots === slot.date && dietitianData.user_id === slot.user_id ? "selected" : ""
                }`}
              onClick={() => {
                setErrorMessage(null);
                setErrorMessageCenter(null);
                toggleSlotSelection(slot, slotList);
              }}
            >
              {slotTime}
            </button>
          )}
        </div>
      );
    });
  };

  function clearSelectedSlot() {
    setSelectedSlots("");
    setDietitianData({});
    setErrorMessage(null);
    setErrorMessageCenter(null);
  }

  useMemo(() => {
    const checkAndDisablePrevButton = () => {
      const cc = document.getElementsByClassName(
        "react-calendar__navigation__prev-button"
      )?.[0];
      if (!cc) {
        // If cc is undefined, wait for a short time and check again
        setTimeout(checkAndDisablePrevButton, 1000);
        return;
      }

      const currMonth = moment(selectedDate).month();
      const currYear = moment(selectedDate).year();

      if (
        currMonth + 1 === moment().month() + 1 &&
        currYear === moment().year()
      ) {
        cc.style.pointerEvents = "none";
        cc.disabled = true;
        cc.style.opacity = 0.5;
      } else {
        if (cc.style.opacity === 1) return;
        cc.style.pointerEvents = "auto";
        cc.disabled = false;
        cc.style.opacity = 1;
      }

      if (!hidePrevArrowOnMonth) return;

      const disableMonth = moment(hidePrevArrowOnMonth).month() + 1;
      const disableYear = moment(hidePrevArrowOnMonth).year();

      if (currMonth === disableMonth && currYear === disableYear) {
        cc.style.pointerEvents = "none";
        cc.disabled = true;
        cc.style.opacity = 0.5;
      } else {
        if (cc.style.opacity === 1) return;
        cc.style.pointerEvents = "auto";
        cc.disabled = false;
        cc.style.opacity = 1;
      }
    };

    // Call the function to start the checking process
    checkAndDisablePrevButton();
  }, [selectedDate]);

  const handleNextButtonClick = (date) => {
    // Handle the next button click event here
    let calDate = moment(date.activeStartDate);
    getAvailableSlotes({
      date: calDate,
      member_ids: organizationMembers.members
        ? organizationMembers.members.map((mem) => mem.id)
        : [],
      evuid: cookies["evuid"],
      appt_type_id:
        organizationMembers?.members?.[0]?.appointment_types?.[0]?.id, // userData.insurence.insurance_provider_id
    });
  };

  const handleContinueButton = () => {
    navigate("/referred");
  };

  const [timeZoneAbbreviation, setTimeZoneAbbreviation] = useState('');

  useEffect(() => {
    const date = new Date();
    const options = { timeZoneName: 'short' };
    const localeString = date.toLocaleTimeString('en-us', options);
    const abbreviation = localeString.split(' ')[2];
    console.log(abbreviation);
    setTimeZoneAbbreviation(abbreviation);
  }, []);


  return (
    <>
      {!loading && (
        <>
          <Header progressValue={progressValue} />
          <TransitionAnimation>
            <div className="container InsuranceInformation">
              <div className="row">
                <div className="col-lg-5 col-md-8 col-sm-12 text-center mx-auto mt-5">
                  <h3 className="mt-4 text-center">{title}</h3>
                </div>
              </div>

              <div className="d-flex flex-column  calender-blck">
                <div className="align-self-center  mt-5">
                  <Calendar
                    minDetail="year"
                    value={selectedDate}
                    calendarType="gregory"
                    onChange={handleDateChange}
                    tileDisabled={tileDisabled}
                    tileClassName={tileClassName}
                    className={`custom-calender`}
                    onActiveStartDateChange={handleNextButtonClick}
                  />
                </div>
                <p className="mt-2 mb-4 text-center grey-text">
                  Availability is displayed in your current time zone ({timeZoneAbbreviation}).
                </p>
                {(errorMessage || errorMessageCenter) && (
                  <p className="error-message text-center text-danger adjust-p mb-2">
                    {errorMessage || errorMessageCenter}
                  </p>
                )}

                {(slotloading || calenderloading) && (
                  <>
                    <div
                      className="container d-flex justify-content-center"
                    >
                      <div className="row">
                        <Spinner text="Please wait" />
                      </div>
                    </div>
                  </>
                )}

                {/* {!slotloading && !calenderloading && slotList.length > 0 && (
                  <p className="my-0 adjust-p">
                    <strong>
                      Dietitians that specialize in{" "}
                      <i>{userData.specialty?.label}</i>
                    </strong>
                  </p>
                )} */}
              </div>

              {/* block section start  */}
              {!slotloading && !calenderloading && (
                <div>
                  <div className="d-flex flex-column justify-content-center align-items-center mt-2">
                    {slotList.length > 0 &&
                      slotList.map((sl, i) => (
                        <Fragment key={i}>
                          <DititianBlock
                            excellentMatch={i === 0 ? true : false}
                            generateSlotButton={generateSlotButton}
                            slotList={sl}
                            subOptionsList={subOptionsList}
                          />
                        </Fragment>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </TransitionAnimation>
          <Modal show={showModal} onHide={handleClose} dialogClassName="modal-dialog-centered" contentClassName="custom-modal-dialog">
            <div className="container h-auto " >
              <div className="row dietitian-screen-block">
                <div className="col1 text-left" style={{ width: "30%" }}>
                  <img
                    src={dietitianModal.dietitian_image || Profile}
                    alt="Dietitian Profile"
                    // height={""}
                    // width={121}
                    className="rounded-circle image-round"
                    style={{ height: "-webkit-fill-available", objectFit: "cover", width: "100%" }}
                  />
                </div>

                <div className="col1 text-left" style={{ width: "70%" }}>
                  {modalLoading == true ? (
                    <Spinner text="Please wait" />
                  ) : (
                    <div className="custom-timer-div">
                      <CountdownTimer callFrom="dietitianScreen" />
                    </div>
                  )}
                  {userData && userData.appointment_data && userData.appointment_data.slot ? (
                    <h6>{moment(userData.appointment_data.slot, 'YYYY-MM-DD HH:mm:ss Z').format('dddd, MMMM D, YYYY')}</h6>
                  ) : (
                    ""
                  )}
                  {slotTime && dietitianModal && dietitianModal?.name ? (
                    <h6>
                      {slotTime}{" "}
                      with {dietitianModal?.name}
                    </h6>
                  ) : (
                    ""
                  )}
                  <div className="custom-continue-div">
                    <button
                      className="popup-btn"
                      onClick={handleContinueButton}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Close
              onClick={handleClose}
              style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
            />

            {/* <button onClick={handleContinueButton} className="coverage-button">
                Continue
                <FontAwesomeIcon className="icon-arrow" color="#fff" icon={faArrowRight} />
              </button> */}
          </Modal>
          {/* <CountdownTimer /> */}
        </>
      )}
      {loading && (
        <div className="container page-main1 ">
          <div className="row">
            <Spinner text="Please wait" />
          </div>
        </div>
      )}
    </>
  );
};
export default AppointmentBooking;
