import React, { Fragment } from "react";
import "./Button.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const CommonButton = ({ id, onClick, className,children,isShowArrow=false, ...rest }) => {
  return (
    <Fragment>
      <button
        id={id}
        type="button"
        className={`common-btn ${className}`}
        onClick={onClick}
        {...rest}>
        {children}
        {/* {isShowArrow && <FontAwesomeIcon className="icon-arrow" color="#fff" icon={faArrowRight} />} */}
      </button>
    </Fragment>
  );
};

export default CommonButton;
