import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  createContext,
  useMemo,
} from "react";
import CVCInput from "./CVCInput";
import DateInput from "./DateInput";
import CardNumberInput from "./CardNumberInput";
import { USER_DATA } from "../../utils/constants/user";
import { useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  CardElement
} from "@stripe/react-stripe-js";

export const CreditCardDataContext = createContext(null);

const useOptions = () => {
  const options = useMemo(
    () => ({
      hidePostalCode: true
    })
  );

  return options;
};

export const CardProvider = ({ children }) => {
  const [cardData, setCardData] = useState(undefined);

  return (
    <CreditCardDataContext.Provider value={{ cardData, setCardData }}>
      {children}
    </CreditCardDataContext.Provider>
  );
};

const CreditCardInput = (props) => {
  const [focusIndex, setFocusIndex] = useState([]);
  const userData = useSelector((state) => state.user[USER_DATA]);
  const options = useOptions();
  const stripe = useStripe();
  const elements = useElements();
  const setUserDataHandler = (uData) => {
    if (uData.payment_method_data) {
    } else {
    }
  };

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const setCardErrorText = (name, text) =>
    props.setCardError({ ...props.cardError, [name]: text });

  const leaveField = (index) => {
    const indexArray = new Array(3).fill(false);
    indexArray[index] = false;
    setFocusIndex(indexArray);
  };

  const setFocusOnFirst = useCallback(() => {
    leaveField(0);
  }, []);

  useEffect(() => {
    leaveField(0);
  }, []);

  useEffect(() => {
    window.addEventListener("focus", setFocusOnFirst);
    return () => {
      window.removeEventListener("focus", setFocusOnFirst);
    };
  }, [setFocusOnFirst]);

  const handleSubmit = async (event) => {

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });

    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      console.error('[Error]', error);
    } else {
      console.log('[PaymentMethod]', token);
    }
  };

  return (
    <CreditCardDataContext.Provider
      value={{ cardData: props.cardData, setCardData: props.setCardData }}
    >
      <Fragment>
        <div className="main-div-one">
          <CardElement
            options={options}
            className="form-control inputbox card-number-element"
            onChange={(event) => handleSubmit(event)}
          />
        </div>
      </Fragment>
    </CreditCardDataContext.Provider>
  );
};

export default CreditCardInput;
